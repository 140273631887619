import Header from '../components/Header';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState } from 'react';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faAwardSimple, faTrophy } from '@fortawesome/pro-regular-svg-icons';

import { IUser } from '../interfaces/IUsers';
import userPicture from '../assets/img-user.png';
import { ICompany } from '../interfaces/ICompany';
import EntrepriseService from '../services/EntrepriseService';

import { faArrowsRotate, faShoePrints, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { IAgenda } from '../interfaces/IAgenda';
import { PropagateLoader } from 'react-spinners';

let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const RankedScreen: React.FC = () => {
    const [token, setToken] = useState('');
    const [users, setUsers] = useState<any>([]);
    const { getItem } = useAsyncStorage('token');
    const [company, setCompany] = useState<ICompany | null>(null);
    const [selectedLink, setSelectedLink] = useState<number | null>(null);
    const [selectedLinkThird, setSelectedLinkThird] = useState<number | null>(null);
    const [selectedLinkSecond, setSelectedLinkSecond] = useState<number | null>(null);

    const [annualAgendas, setAnnualAgendas] = useState([]);
    const [selectedAgenda, setSelectedAgenda] = useState<IAgenda>();
    const [classicalAgendas, setClassicalAgendas] = useState([]);
    const [loading, setLoading] = useState(true);

    const isPhone = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();
                if (savedToken !== null) {
                    setToken(savedToken);
                    console.log('Token loaded :', savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };

        getToken();
    }, []);

    useEffect(() => {
        if (!token) return;

        EntrepriseService.getCompany(token).then((data: any) => {
            setCompany(data);
            setLoading(false);
        }).catch((error: any) => {
            console.error('Error fetching challenges:', error);
        });
    }, [token]);

    useEffect(() => {
        if (!token || token == undefined || !company || company == undefined) return;

        EntrepriseService.getCompanyAgendas(token, company._id).then((agendas: any) => {
            const classical = agendas.filter((agenda: any) => agenda.type === 'Classical');
            setClassicalAgendas(classical);
            setLoading(false);
            const annual = agendas.filter((agenda: any) => agenda.type === 'Annual');
            setAnnualAgendas(annual);
            setLoading(false);
            if (classical.length > 0) {
                console.log('Classical:', classical);
                setSelectedAgenda(classical[0])
                fetchData(classical[0]);
                setSelectedLink(1);
            } else {
                console.log('Annual:', annual);
                setSelectedAgenda(annual[0])
                fetchData(annual[0]);
                setSelectedLink(0);
            }
        });
    }, [token, company]);

    const fetchData = async (agenda: any) => {
        if (!token || !company) return;

        let type = ((company.isOnlyTeamGame) ? "team" : "individual");

        setSelectedLinkSecond(type === "team" ? 1 : 0);
        setSelectedLinkThird(0);

        EntrepriseService.getCompanyRanking(token, type, "thisMonth", "sum", agenda?._id, agenda?.type).then((users) => {
            setUsers(users);
            setLoading(false);
        });
    };

    const handleLinkClick = (index: number) => {
        setSelectedLink(index);

        if (index === 0 && annualAgendas.length > 0) {
            setSelectedAgenda(annualAgendas[0]);
            fetchData(annualAgendas[0]);
        } else if (index === 1 && classicalAgendas.length > 0) {
            setSelectedAgenda(classicalAgendas[0]);
            fetchData(classicalAgendas[0]);
        }
    };

    const handleLinkClickSecond = (index: number) => {
        if (index === 1 && !company?.isTeamAllowed) return;

        setSelectedLinkSecond(index);

        let type = ((index === 1) ? "team" : "individual");

        let agendaId = selectedAgenda?._id || '';
        let agendaType = selectedAgenda?.type || '';

        EntrepriseService.getCompanyRanking(token, type, "thisMonth", "sum", agendaId, agendaType).then((users) => {
            setUsers(users);
        });
    };

    const handleLinkClickThird = (index: number) => {
        setSelectedLinkThird(index);

        let method = ((index === 0) ? "sum" : "avg");
        let type = ((selectedLinkSecond === 1) ? "team" : "individual");

        let agendaId = selectedAgenda?._id || '';
        let agendaType = selectedAgenda?.type || '';

        EntrepriseService.getCompanyRanking(token, type, "thisMonth", method, agendaId, agendaType).then((users) => {
            setUsers(users);
        });
    };

    if (loading) {
        return (
            <div className="container-loading">
                <PropagateLoader color="#4F12B8" />
            </div>
        );
    }

    return (
        <div className='container'>
            <Header title='Classement' />

            <div className='stats-date-container item-container'>
                <div className='stat-date-title'>
                    <h3>Ranger l'ordre de classement par agenda:</h3>

                    <a className='refresh-icon-responsive' href="">
                        <div className=''>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>

                <div className='left-date-link-container'>
                    <div className={selectedLink === 0 || 1 ? "date-link-container-selected" : 'date-link-container'}>
                        <a href="#" onClick={() => handleLinkClick(0)} className={selectedLink === 0 ? 'selected-link' : 'link'}>Annuel</a>
                        <a href="#" onClick={() => handleLinkClick(1)} className={selectedLink === 1 ? 'selected-link' : 'link'}>Classique</a>
                    </div>

                    <a href="">
                        <div className='refresh-icon'>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>
            </div>

            <div className='stats-date-container item-container'>
                <div className='stat-date-title'>
                    <h3>Filtrer le classement:</h3>

                    <a className='refresh-icon-responsive' href="">
                        <div className=''>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>

                <div className='left-date-link-container'>
                    <div className={selectedLinkSecond === 0 || 1 ? "date-link-container-selected" : 'date-link-container'}>
                        <a href="#" onClick={() => handleLinkClickSecond(0)} className={selectedLinkSecond === 0 ? 'selected-link' : 'link'}>Individuel</a>
                        <a href="#" onClick={() => handleLinkClickSecond(1)} className={selectedLinkSecond === 1 ? 'selected-link' : 'link'}>Par équipe</a>
                    </div>

                    <a href="">
                        <div className='refresh-icon'>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>
            </div>

            {selectedLinkSecond === 1 && (
                <div className='stats-date-container item-container'>
                    <div className='stat-date-title'>
                        <h3>Calculer les points par:</h3>

                        <a className='refresh-icon-responsive' href="">
                            <div className=''>
                                <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                            </div>
                        </a>
                    </div>

                    <div className='left-date-link-container'>
                        <div className={selectedLinkThird === 0 || 1 ? "date-link-container-selected" : 'date-link-container'}>
                            <a href="#" onClick={() => handleLinkClickThird(0)} className={selectedLinkThird === 0 ? 'selected-link' : 'link'}>Totalité des points</a>
                            <a href="#" onClick={() => handleLinkClickThird(1)} className={selectedLinkThird === 1 ? 'selected-link' : 'link'}>Moyenne</a>
                        </div>

                        <a href="">
                            <div className='refresh-icon'>
                                <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                            </div>
                        </a>
                    </div>
                </div>
            )}

            {users.length === 0 || loading ?  (
               <div className="container-loading">
                    <PropagateLoader color="#4F12B8" />
                </div>
            ) : (
                <div className='item-container'>
                    {!isPhone && (
                        <div className='top-rank-list-container'>
                            {users.length >= 2 && (
                                [users[1], users[0], ...users.slice(2, 3)].map((person: any, index: number) => {
                                    const adjustedIndex = index === 0 ? 1 : (index === 1 ? 0 : 2);
                                    return (
                                        <div className='top-rank-container' key={person.rank}>
                                            <div className='picture-container'>
                                                <img src={`${BASE_URL}/upload/profile/${person.picture}`} alt='UserPicture' className='user-picture display-none' />
                                                <div className='user-icon'>
                                                    {adjustedIndex === 0 && <FontAwesomeIcon icon={faTrophy} size='lg' />}
                                                    {adjustedIndex === 1 && <FontAwesomeIcon icon={faAward} size='lg' />}
                                                    {adjustedIndex === 2 && <FontAwesomeIcon icon={faAwardSimple} size='lg' />}
                                                </div>
                                            </div>

                                            <div className={`user-info-container top${adjustedIndex + 1}`}>
                                                <h2 className={`top${adjustedIndex + 1}-text`}>{person.name}</h2>
                                                <p className={`top${adjustedIndex + 1}-text`}>{person.score} pts</p>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    )}

                    <div />

                    <div className='rank-users-container'>
                        <ul className='rank-users-list-container'>
                            {users.map((person: any) => (
                                <li className='info-users' key={person.rank}>
                                    <div className='user-name'>
                                        <p>{person.rank}</p>
                                        <img src={`${BASE_URL}/upload/profile/${person.picture}`} alt={person.name} className='pitcure-users-list display-none' />
                                        <p>{person.name}</p>
                                    </div>
                                    <div className='users-point-container'>
                                        <p>{person.score} pts</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RankedScreen;
