import axios from "axios";
let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const ChatService = {
    async getStatsByPeriod(token: string, period: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/chats/chat/${period}/period`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async getTeamChat(token: string, teamId: any, type: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/chats/chat/getChat/${type}/${teamId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async getTeamUsers(token: string, teamId: any) {
        try {
            const response = await axios.get(`${BASE_URL}/api/teams/team/${teamId}/members`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async getMessagesDays(token: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/chats/chat/days`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },
};

export default ChatService;
