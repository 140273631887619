import "../App.css";
import React, { useEffect, useState } from 'react';

import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import { IUser } from '../interfaces/IUsers';
import UserService from '../services/UserService';

interface HeaderProps {
    title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
    const [token, setToken] = useState('');
    const [user, setUser] = useState<IUser>();
    const { getItem } = useAsyncStorage('token');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();

                if (savedToken !== null) {
                    setToken(savedToken);
                    console.log('Token loaded :', savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };

        getToken();
    }, []);

    useEffect(() => {
        if (!token) return;

        UserService.getUser(token).then((data: any) => {
            setUser(data);
        }).catch((error: any) => {
            console.error('Error fetching challenges:', error);
        });
    }, [token]);

    const { removeItem } = useAsyncStorage('token');

    const handleLogout = async () => {
        console.log('Logout');
        try {
            await removeItem();
            setToken('');
            setUser(undefined);
            window.location.reload();
        } catch (error) {
            console.error('Error removing token from AsyncStorage:', error);
        }
    };

    return (
        <div className='header-container'>
            <div className='header-title-container'>
                <h1>{title}</h1>
            </div>

            <div className='header-profil'>
            <div className='header-profil-container' onClick={toggleDropdown}>
                <div className='initial-profil'>
                    <h3 className="rounded-name">{user?.firstname.charAt(0).toUpperCase()}{user?.lastname.charAt(0).toUpperCase()}</h3>
                </div>
                <h3>{user?.firstname} {user?.lastname}</h3>
                <FontAwesomeIcon icon={faChevronDown} className='chevronDown-logo' />
            </div>
            {isDropdownOpen && (
                <div className='dropdown-menu'>
                    <button onClick={handleLogout} className='logout-button'>Déconnexion</button>
                </div>
            )}
        </div>
        </div>
    );
};

export default Header;
