import './App.css';
import HomeScreen from './screens/HomeScreen';
import ChatScreen from './screens/ChatScreen';
import RankedScreen from './screens/RankedScreen';
import React, { useState, useEffect } from 'react';
import logoTeamupp from './assets/LogoTeamupp.png';
import ChallengeScreen from './screens/ChallengeScreen';
import EntrepriseScreen from './screens/EntrepriseScreen';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';

import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faArrowUpWideShort,
    faBuildings,
    faChartLineUp,
    faCommentLines,
    faFlag,
    faHouseBlank
} from '@fortawesome/pro-regular-svg-icons';
import LoginScreen from './screens/LoginScreen';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

function App() {
    const [token, setToken] = useState('');
    const [activeLink, setActiveLink] = useState('Accueil');
    const [isOpen, setIsOpen] = useState(false);

    const handleLinkClick = (link: any) => {
        setActiveLink(link);
    };

    const { getItem } = useAsyncStorage('token');

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();

                if (savedToken !== null) {
                    setToken(savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };

        getToken();

        const interval = setInterval(getToken, 100);

        return () => clearInterval(interval);
    }, [getItem]);

   

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div>
            {token ? (
                <Router>
                    <nav className='navBarConainer'>
                        <div>
                            <img src={logoTeamupp} className="logoTeamupp" alt="logo" />
                        </div>
                        <Link to="/" onClick={() => { handleLinkClick('Accueil') }} style={{ textDecoration: "none" }}>
                            <div className={activeLink === 'Accueil' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                                <FontAwesomeIcon icon={faHouseBlank} size='xl' className="logoItem" />
                                <h3 className='titleItemNavBar'>Accueil</h3>
                            </div>
                        </Link>
                        <Link to="/challenge" onClick={() => { handleLinkClick('Challenge') }} style={{ textDecoration: "none" }}>
                            <div className={activeLink === 'Challenge' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                                <FontAwesomeIcon icon={faFlag} size='xl' className="logoItem" />
                                <h3 className='titleItemNavBar'>Challenges</h3>
                            </div>
                        </Link>
                        <Link to="/classement" onClick={() => { handleLinkClick('classement') }} style={{ textDecoration: "none" }}>
                            <div className={activeLink === 'classement' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                                <FontAwesomeIcon icon={faArrowUpWideShort} size='xl' className="logoItem" />
                                <h3 className='titleItemNavBar'>Classement</h3>
                            </div>
                        </Link>
                        <Link to="/chat" onClick={() => { handleLinkClick('chat') }} style={{ textDecoration: "none" }}>
                            <div className={activeLink === 'chat' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                                <FontAwesomeIcon icon={faCommentLines} size='xl' className="logoItem" />
                                <h3 className='titleItemNavBar'>chat</h3>
                            </div>
                        </Link>
                        <Link to="/entreprise" onClick={() => { handleLinkClick('Entreprise') }} style={{ textDecoration: "none" }}>
                            <div className={activeLink === 'Entreprise' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                                <FontAwesomeIcon icon={faBuildings} size='xl' className="logoItem" />
                                <h3 className='titleItemNavBar'>Entreprise</h3>
                            </div>
                        </Link>
                        {/* <Link to="/Reporting" onClick={() => { handleLinkClick('Reporting') }} style={{ textDecoration: "none" }}>
                            <div className={activeLink === 'Reporting' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                                <FontAwesomeIcon icon={faChartLineUp} size='xl' className="logoItem" />
                                <h3 className='titleItemNavBar'>Reporting et KPI</h3>
                            </div>
                        </Link> */}
                    </nav>
                    <nav className='navBar-phone'>
                <div className='nav-phone-container'>
                    <img src={logoTeamupp} alt="logo" className='img-phone' />
                    <FontAwesomeIcon icon={faBars} size='1x' className='icon-phone' onClick={handleToggle} />
                </div>
            </nav>
            {isOpen && (
                <div className="navbar">
                    <ul className="navbar-list">
                        <li className="navbar-item">
                            <Link to="/" className="navbar-link" onClick={handleToggle}>Accueil</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/challenge" className="navbar-link" onClick={handleToggle}>Challenges</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/chat" className="navbar-link" onClick={handleToggle}>Chat</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/classement" className="navbar-link" onClick={handleToggle}>Classement</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/entreprise" className="navbar-link" onClick={handleToggle}>Entreprise</Link>
                        </li>
                    </ul>
                </div>
            )}
                    

                    <Routes>
                        <Route path="/" element={<HomeScreen />} />
                        <Route path="/challenge" element={<ChallengeScreen />} />
                        <Route path="/classement" element={<RankedScreen />} />
                        <Route path="/chat" element={<ChatScreen />} />
                        <Route path="/entreprise" element={<EntrepriseScreen />} />
                    </Routes>
                </Router>
            ) : (
                <LoginScreen />
            )}
        </div>
    );
}

export default App;
