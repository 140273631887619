import axios from "axios";
let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const UserService = {
    async getUser(token: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/users/user/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },
};

export default UserService;
