import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import ChartJS from 'chart.js/auto';

interface BarChartProps {
    width?: string;
    height?: string;
    title?: string;
    activityData: [string, number][];
    lineColor?: string;
    borderColor?: string;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart: React.FC<BarChartProps> = ({ activityData }) => {
    const chartRef = React.useRef(null);

    const options = {
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 2,
                borderRadius: 20,
            },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart',
                color: 'black',
            },
        },
        layout: {
            padding: {
                top: 0,
            },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
    };

    const labels = activityData.map(activity => activity[0]);
    const dataValues = activityData.map(activity => activity[1]);

    const data = {
        labels,
        datasets: [
            {
                label: "Nombre d'utilisateur",
                data: dataValues,
                borderColor: '#E0CEFF',
                backgroundColor: '#9747FF1A',
            },
        ],
    };

    return (
        <div className='test'>
            <h2 className='title-chart'>Types d'activités réalisées</h2>
            <br /><br />
            <Bar ref={chartRef} data={data} options={options} />
        </div>
    );
};

export default BarChart;
