import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartJS from 'chart.js/auto';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarVerticalChartProps {
  width?: string;
  height?: string;
  title?: string;
  labels: string[];
  dataset?: number[];
  lineColor?: string;
  borderColor?: string;
}

const BarChartChat: React.FC<BarVerticalChartProps> = ({
  width,
  height,
  title,
  labels,
  dataset,
  lineColor,
  borderColor,
}) => {
  const chartRef = useRef(null);

  const chartStyle = {
    width: width || '100%',
    height: height || '400px',
    maxHeight: '400px',
  };

  const options = {
    indexAxis: 'x' as const,
    elements: {
      bar: {
        borderWidth: 2,
        borderRadius: 20,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Horizontal Bar Chart',
        color: 'black',
      },
    },
    layout: {
      padding: {
        top: 0,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, 
        },
      },
      y: {
        grid: {
          display: true, 
        },
      },
    },
  };

  const generateRandomData = () => {
    return labels.map(() => Math.floor(Math.random() * 55));
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Nb de messages',
        data: dataset || generateRandomData(),
        borderColor: borderColor || '#E0CEFF',
        backgroundColor: `${lineColor || '#FBF7FF'}4D`,
      },
    ],
  };

  return (
    <div>
      <h2 className='title-chart'>{title || 'Nombre de connexions journalières - Février 2024'}</h2>
      <Bar ref={chartRef} style={chartStyle} data={data} options={options} />
    </div>
  );
};

export default BarChartChat;
