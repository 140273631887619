import { faArrowUpRight, faChevronDown, faChevronLeft, faChevronRight, faFlag, faMobile, faArrowsRotate, faShoePrints, faUserPlus, faRunning, faBiking, faSwimmer } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import HorizontalBarChart from '../components/BarChart';
import BarVerticalChart from '../components/BarChartVertical';
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import EntrepriseService from '../services/EntrepriseService';
import { PropagateLoader } from 'react-spinners';

const HomeScreen = () => {
    const [token, setToken] = useState('');
    const [stats, setStats] = useState<any>();
    const { getItem } = useAsyncStorage('token');
    const [selectedLink, setSelectedLink] = useState(0);
    const [messagesByDay, setMessagesByDay] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();
                if (savedToken !== null) {
                    setToken(savedToken);
                    console.log('Token loaded :', savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };

        getToken();
    }, []);

    useEffect(() => {
        if (!token) return;

        EntrepriseService.getActivitiesStats(token, "thisMonth").then((stats) => {
            console.log("----")
            setStats(stats);
            console.log('Stats loaded :', stats);
            setLoading(false);
        });
    }, [token]);

    const handleLinkClick = (index:any) => {
        setSelectedLink(index);
        let period = (index === 0) ? 'thisMonth' : (index === 1) ? 'thisWeek' : 'today';

        EntrepriseService.getActivitiesStats(token, period).then((stats) => {
            setStats(stats);
            console.log('Stats loaded :', stats);
            setLoading(false);
        });
    };

    if (loading) {
        return (
            <div className="container-loading">
                <PropagateLoader color="#4F12B8" />
            </div>
        );
    }

    const handleDragStart = (e:any) => e.preventDefault();
    const distances:any = {
        running: 0,
        swimming: 0,
        walk: 0,
        cycling: 0,
    };
    
    stats.sumActivities.forEach((activity:any) => {
        distances[activity._id] = activity.distance ? activity.distance : 0;
    });

    const itemsLaptop = [
      
        <div key="1">
            <div className='row'>
                <div className='card-container'>
                    <div className='card-logo-container'>
                        <FontAwesomeIcon icon={faShoePrints} rotation={270} size='2xl' />
                    </div>
                    <div className='title-card'>
                        <h3>{Math.round(stats.totalSteps)} pas</h3>
                    </div>
                    <div className='desc-card'>
                        <p>Nombre de pas réalisés sur la période</p>
                    </div>
                </div>

                <div className='card-container'>
                    <div className='card-logo-container'>
                        <FontAwesomeIcon icon={faRunning}  size='2xl' />
                    </div>
                    <div className='title-card'>
                        <h3>{Math.round(distances.running)} kms</h3>
                    </div>
                    <div className='desc-card'>
                        <p>Distance totale de course à pied</p>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='card-container'>
                    <div className='card-logo-container'>
                        <FontAwesomeIcon icon={faBiking} size='2xl' />
                    </div>
                    <div className='title-card'>
                    <h3>{Math.round(distances.cycling)} kms</h3>
                    </div>
                    <div className='desc-card'>
                        <p>Distance totale en vélo</p>
                    </div>
                </div>

                <div className='card-container'>
                    <div className='card-logo-container'>
                        <FontAwesomeIcon icon={faSwimmer} size='2xl' />
                    </div>
                    <div className='title-card'>
                        <h3>{Math.round(distances.swimming)} kms</h3>
                    </div>
                    <div className='desc-card'>
                        <p>Distance totale de nage</p>
                    </div>
                </div>
            </div>

            
        </div>



        
    ];

    const itemsPhone = [
            
                <div className='card-container'>
                    <div className='card-logo-container'>
                        <FontAwesomeIcon icon={faShoePrints} rotation={270} size='2xl' />
                    </div>
                    <div className='title-card'>
                        <h3>{Math.round(stats.totalSteps)} pas</h3>
                    </div>
                    <div className='desc-card'>
                        <p>Nb de pas réalisé dans l'entreprise</p>
                    </div>
                </div>,

                <div className='card-container'>
                    <div className='card-logo-container'>
                        <FontAwesomeIcon icon={faShoePrints} rotation={270} size='2xl' />
                    </div>
                    <div className='title-card'>
                        <h3>{Math.round(stats.totalDistance)} km</h3>
                    </div>
                    <div className='desc-card'>
                        <p>Distance totale parcourue dans l'entreprise</p>
                    </div>
                </div>
            
    ];
    const renderPrevButton = () => (
        <div className='skip-icon'>
            <FontAwesomeIcon icon={faChevronLeft} size='lg' />
        </div>
    );

    const renderNextButton = () => (
        <div className='skip-icon'>
            <FontAwesomeIcon icon={faChevronRight} size='lg' />
        </div>
    );

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
        1444: { items: 1 },
    };

    const responsivePhone = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
        1444: { items: 1 },
    };

    return (
        <div className='container'>
            <Header title='Tableau de bord' />

            <div className='stats-date-container item-container'>
                <div className='stat-date-title'>
                    <h3>Statistiques sur période sélectionnée</h3>
                    <a className='refresh-icon-responsive' href="">
                        <div className=''>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>

                <div className='left-date-link-container'>
                    <div className={selectedLink === 0 || 1 || 2 ? "date-link-container-selected" : 'date-link-container'}>
                        <a href="#" onClick={() => handleLinkClick(0)} className={selectedLink === 0 ? 'selected-link' : 'link'}>Ce mois-ci</a>
                        <a href="#" onClick={() => handleLinkClick(1)} className={selectedLink === 1 ? 'selected-link' : 'link'}>Cette semaine</a>
                        <a href="#" onClick={() => handleLinkClick(2)} className={selectedLink === 2 ? 'selected-link' : 'link'}>Aujourd'hui</a>
                    </div>
                    <a href="">
                        <div className='refresh-icon'>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>
            </div>

            <div className='content-top item-container'>
              {/*   <div className='carrousel-phone-container'>
                    <AliceCarousel
                        mouseTracking
                        items={itemsPhone}
                        responsive={responsivePhone}
                    />
                </div> */}

                <div className='all-card-container'>
                    <AliceCarousel
                        mouseTracking
                        autoWidth
                        items={itemsLaptop}
                        responsive={responsive}
                        renderPrevButton={renderPrevButton}
                        renderNextButton={renderNextButton}
                    />
                </div>

                <div className='container-chart'>
                    <HorizontalBarChart activityData={stats.activitiesCount} />
                </div>
            </div>
{/* 
            <div className='item-container container-chart-vertical'>
                <div className='chart-vertical'>
                    <BarVerticalChart
                        width="80%"
                        height="400px"
                        title="Nombre de connexions journalières - Février 2024"
                        labels={chartData.labels}
                        dataset={chartData.dataset}
                        lineColor="#F9D27E"
                    />
                </div>
            </div> */}
        </div>
    );
};

export default HomeScreen;
